.empty-cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  color: #333;
  cursor: pointer;
}

.cart-image img {
  margin-top: -18em;
  width: 300px;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
}

p {
  font-size: 16px;
  color: #777;
}

/* Tablet Styles (max-width: 768px) */
@media (max-width: 768px) {
  .cart-image img {
    margin-top: -10em;
    width: 250px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }
}

/* Mobile Styles (max-width: 480px) */
@media (max-width: 480px) {
  .empty-cart-container {
    padding: 0 20px;
  }

  .cart-image img {
    margin-top: -5em;
    width: 200px;
  }

  h2 {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }
}
