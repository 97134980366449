.order-details-container {
  max-width: 90%;
  margin-top: 7%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.order-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.order-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.order-product-info {
  display: flex;
  align-items: center;
}

.order-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.order-description {
  display: flex;
  flex-direction: column;
}

.order-description h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.order-description p {
  margin: 5px 0;
  color: #666;
}

.order-details-container h3 {
  text-align: center;
  margin-bottom: 20px;
}

.back-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 35%;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #f03434;
}

.back-button:focus {
  outline: none;
}

.back-button:active {
  background-color: #ff4d4d;
}

/* Tablet view*/
@media (max-width: 768px) {
  .order-grid {
    grid-template-columns: 1fr;
  }

  .order-details-container {
    margin-top: 10%;
    padding: 15px;
  }

  .order-item {
    padding: 10px;
  }

  .order-image {
    width: 80px;
    height: 80px;
    margin-right: 15px;
  }

  .order-description h3 {
    font-size: 16px;
  }

  .order-description p {
    font-size: 14px;
  }
}

/* Mobile Version (max-width: 480px) */
@media (max-width: 480px) {
  .order-grid {
    grid-template-columns: 1fr;
  }

  .order-details-container {
    margin-top: 12%;
    padding: 10px;
  }

  .order-item {
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }

  .order-image {
    width: 70px;
    height: 70px;
    margin: 0 auto 10px;
  }

  .order-description h3 {
    font-size: 14px;
  }

  .order-description p {
    font-size: 12px;
  }
  .back-button {
    margin-left: 10%;
  }
}
