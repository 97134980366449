.form-order-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  max-width: 1400px;
  margin-top: 50px;
  padding: 40px 20px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container {
  flex: 2;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.order-summary-container {
  flex: 1;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.form-container h2 {
  text-align: left;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 2px solid #ff4d4d;
  padding-bottom: 10px;
}

.form-group {
  flex: 1;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.form-row {
  display: flex;
  gap: 20px;
}
.termConditions {
  color: #797988;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

label {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input[type="text"],
input[type="email"],
input[type="number"],
select,
input[type="checkbox"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
select:focus {
  outline: none;
  border-color: #0066ff;
  box-shadow: 0 0 5px rgba(64, 108, 240, 0.5);
}

.form-note {
  font-size: 14px;
  color: #d9534f;
  margin-top: 5px;
}

.submitBtn {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: #ff4d4d;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.order-summary {
  font-size: 16px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0;
}

.total {
  font-weight: bold;
  font-size: 18px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.error {
  color: red;
  font-size: 0.875em;
  margin-top: 5px;
}

.error-input {
  border: 1px solid red;
}

.form-group {
  margin-bottom: 20px;
}

.enquiry-notification {
  position: fixed;
  top: 0;
  left: 75%;
  width: 20%;
  padding: 1em;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  z-index: 1000;
}
.notification-success {
  background-color: #28a745;
  color: #fff;
}
.notification-error {
  background-color: #dc3545;
  color: #fff;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner-text {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.successSpinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #ff4d4d;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.success-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background-color: white;
  padding: 30px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 1000;
  text-align: center;
}

.success-modal p {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
}

.success-modal button {
  padding: 12px 24px;
  background-color: #ff4d4d;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.success-modal button:hover {
  background-color: #f83535;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

@media (max-width: 768px) {
  .form-order-container {
    flex-direction: column;
  }

  .order-summary-container {
    max-width: 100%;
  }
}
