@import url("https://fonts.googleapis.com/css2?family=Srisakdi:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Srisakdi:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Rubik+Moonrocks&family=Srisakdi:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Bungee+Tint&family=Cabin+Sketch:wght@400;700&family=Rubik+Moonrocks&family=Srisakdi:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Bungee+Tint&family=Cabin+Sketch:wght@400;700&family=Rampart+One&family=Rubik+Moonrocks&family=Srisakdi:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Bungee+Shade&family=Bungee+Tint&family=Cabin+Sketch:wght@400;700&family=Rampart+One&family=Rubik+Moonrocks&family=Srisakdi:wght@400;700&display=swap");
.app {
  font-family: Arial, sans-serif;
}

.main-content {
  display: flex;
}

body {
  background-size: cover;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
