.sidebar-container {
  border: 1px solid #e0e0e0;
  margin-top: 8%;
  margin-left: 1%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
}

.sidebar-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.category-list {
  list-style-type: none;
  padding: 5%;
  cursor: pointer;
}

.category-item {
  font-size: 16px;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  color: #333;
  cursor: pointer;
}

.category-item.selected {
  font-weight: bold;
  color: #000;
}

.category-item:last-child {
  border-bottom: none;
}

.item-count {
  font-size: 14px;
  color: #aaa;
}

/* Tablet Version (max-width: 768px) */
@media (max-width: 768px) {
  .sidebar-container {
    margin-top: 5%;
    margin-left: 0;
    padding: 8px;
  }

  .sidebar-title {
    font-size: 16px;
  }

  .category-list {
    padding: 0;
  }

  .category-item {
    font-size: 14px;
    padding: 10px 0;
  }

  .item-count {
    font-size: 12px;
  }
}

/* Mobile Version (max-width: 480px) */
@media (max-width: 480px) {
  .sidebar-container {
    margin-top: 3%;
    padding: 5px;
    border-radius: 4px;
  }

  .sidebar-title {
    font-size: 14px;
  }

  .category-item {
    font-size: 13px;
    padding: 8px 0;
  }

  .category-item.selected {
    font-weight: bold;
    color: #000;
  }

  .item-count {
    font-size: 11px;
  }
}
