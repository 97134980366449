.header {
  position: fixed;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

.header .logo {
  display: flex;
  align-items: center;
  font-family: "Rampart One", sans-serif;
  font-size: 40px;
  font-weight: bolder;
  margin-top: 15px;
  margin-left: 15px;
  text-decoration: none;
}

.header .logo img {
  margin-right: 10px;
}

.header .logo a {
  text-decoration: none;
  color: #ff4d4d;
}

/* Hide the menu by default for mobile */
.nav-links {
  display: none;
  list-style: none;
  gap: 20px;
  margin: 0 auto;
  margin-top: 30px;
}
nav {
  flex: 1;
  display: flex;
  justify-content: center;
}

nav a {
  text-decoration: none;
  font-size: 20px;
  color: #ff4d4d;
}

nav a:hover {
  font-size: 22px;
  text-decoration: none;
}

/* Show the menu (for mobile) */
.nav-links.show {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.header .cart {
  margin-right: 4%;
  margin-top: 1%;
  text-align: right;
}

.header-cart-container {
  position: relative;
  display: inline-block;
}

.cart-count {
  position: absolute;
  top: 10px;
  right: 100%;
  background-color: #ff4d4d;
  color: white;
  border-radius: 50%;
  padding: 7px;
  font-size: 0.8rem;
}

.call-us {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 15px;
}

.phone-number {
  color: #ff4d4d;
  text-decoration: none;
  font-size: 20px;
}

.phone-number:hover {
  text-decoration: none;
}

.nav-links li {
  list-style: none;
  display: inline-block;
  margin: 0 10px;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #ff4d4d;
  transition: 0.4s;
}

/* for Mobile  */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-links.show {
    display: flex;
    margin-top: -5px;
  }

  .hamburger {
    display: block;
  }

  nav ul li {
    padding: 10px;
    text-align: center;
  }

  .cart {
    margin-right: 10px;
  }

  .cart-count {
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 0.7rem;
  }
}

/* Larger Screens (Desktop) */
@media (min-width: 769px) {
  .nav-links {
    display: flex;
    gap: 20px;
  }

  .hamburger {
    display: none;
  }

  nav ul {
    margin-left: 0;
    margin-left: -35%;
    list-style: none;
  }
}

/* Smaller Mobile Screens */
@media (max-width: 480px) {
  .header {
    height: 50px;
    padding: 5px;
  }

  .header .logo {
    font-size: 18px;
  }

  nav ul li a {
    font-size: 16px;
  }

  .cart {
    margin-right: 5px;
  }

  .cart-count {
    padding: 3px;
    margin-right: 45px;
    font-size: 0.6rem;
  }
}
