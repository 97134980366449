.shop-page-container {
  display: flex;
  margin-top: 6%;
  max-width: 100%;
}

.sidebar {
  flex: 0 0 20%;
}

.shop-content {
  flex: 1;
  padding: 20px;
  margin-left: 1%;
  min-width: 75%;
}

.shop {
  padding: 20px;
  margin-top: 6%;
}

.shop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  z-index: 10;
}
.shop-header p {
  font-size: 0.6em;
}

.shop-control-bar {
  background-color: #f5f5f5;
  padding: 0.714em 1.429em;
  border-radius: 9px;
}

.shop-header .sort-options {
  display: flex;
  gap: 10px;
}

.shop-header .sort-options select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #f8f8f8;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.product-grid > div {
  flex: 1;
  margin: 1px;
  box-sizing: border-box;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 15px 0;
  border-top: 1px solid #e6e6e6;
}

.sort-dropdown {
  position: relative;
  font-family: "Arial", sans-serif;
}

.sort-dropdown-btn {
  background-color: #ff4d4d;
  color: #ffffff;
  border: none;
  padding: 12px 18px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  gap: 2px;
}

.sort-dropdown-btn:hover {
  background-color: #ef5454;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.sort-arrows {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: center;
}
.arrow-up,
.arrow-down {
  font-size: 12px;
  line-height: 1;
}

.sort-dropdown-content {
  position: absolute;
  top: 120%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: -10px;
  z-index: 5;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  min-width: 150px;
  display: none;
}

.sort-dropdown-content.show {
  display: block;
}

.sort-item {
  padding: 12px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.sort-item:hover {
  background-color: #ff4d4d;
  color: #ffffff;
}

.sort-item:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.shop-spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}

.shop-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #ff4d4d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.discount_display {
  color: #ff4d4d;
  margin-left: 10%;
}

/* Mobile Version */
@media (max-width: 768px) {
  .shop-page-container {
    flex-direction: column;
    margin-top: 10%;
  }

  .sidebar {
    flex: 1;
    width: 100%;
    margin-bottom: 20px;
  }

  .shop-content {
    margin-left: 0;
    padding: 10px;
  }

  .shop-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .shop-header .sort-options {
    width: 100%;
    margin-top: 15px;
    justify-content: flex-start;
  }

  .product-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .pagination {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .sort-dropdown-btn {
    font-size: 14px;
    padding: 10px 15px;
  }
  .discount_display {
    color: #ff4d4d;
    margin-left: 0%;
  }
}

@media (max-width: 480px) {
  .shop-page-container {
    margin-top: 15%;
  }

  .product-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .product-grid > div {
    flex: 1;
    margin: 1px;
    box-sizing: border-box;
    min-height: 250px;
  }

  .shop-header .sort-options select {
    width: 100%;
  }

  .sort-dropdown-btn {
    font-size: 12px;
    padding: 8px 12px;
  }

  .pagination {
    width: 100%;
    justify-content: space-between;
  }
  .discount_display {
    color: #ff4d4d;
    margin-left: 0%;
  }
}
