.reset-password-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 400px;
  max-width: 100%;
  text-align: center;
  margin: 100px auto;
}

.reset-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.reset-underline {
  width: 40px;
  height: 3px;
  background-color: #ff4d4d;
  margin: 10px auto 20px;
}

.reset-form {
  display: flex;
  flex-direction: column;
}

.input-container {
  text-align: left;
  margin-bottom: 20px;
}

input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
}

.reset-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.reset-button:hover {
  background-color: #f13434;
}

.back-link {
  display: inline-block;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.back-link:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 20px;
}

/* Large tablets */
@media (max-width: 1024px) {
  .reset-password-container {
    width: 80%;
    padding: 30px;
    margin-top: 80px;
  }

  .reset-title {
    font-size: 22px;
  }

  .reset-button {
    font-size: 15px;
  }
}

/* Small tablets and mobile devices */
@media (max-width: 768px) {
  .reset-password-container {
    width: 90%;
    padding: 25px;
    margin-top: 60px;
  }

  .reset-title {
    font-size: 20px;
  }

  input[type="password"] {
    padding: 8px;
    font-size: 13px;
  }

  .reset-button {
    font-size: 14px;
    padding: 10px;
  }

  .back-link {
    font-size: 13px;
  }
}

/* Mobile devices  */
@media (max-width: 480px) {
  .reset-password-container {
    width: 95%;
    padding: 20px;
    margin-top: 40px;
  }

  .reset-title {
    font-size: 18px;
  }

  .reset-button {
    font-size: 12px;
    padding: 8px;
  }

  .back-link {
    font-size: 12px;
  }

  input[type="password"] {
    padding: 7px;
    font-size: 12px;
  }
}
