.ourProduct-grid {
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.ourProduct-card {
  width: 100%;
  min-height: 250px;
  border: 2px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.ourProduct-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.ourProduct-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.ourProductsHeader h2 {
  color: #ff4d4d;
}

.ourProduct-card h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #ff4d4d;
}
.ourProductsHeader p {
  font-size: 0.6em;
  text-transform: none;
}

.ourProductsHeader {
  text-align: center;
  color: #ff4d4d;
  font-weight: 700;
  text-transform: uppercase;
}

/* Mobile Version */
@media (max-width: 768px) {
  .ourProduct-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
    gap: 15px;
    padding: 10px;
  }

  .ourProduct-card {
    padding: 15px;
  }

  .ourProduct-card img {
    height: 200px;
  }

  .ourProduct-card h3 {
    font-size: 1rem;
  }

  .ourProductsHeader h2 {
    font-size: 1.5rem;
  }
  /* .cart {
    margin-right: 100px;
  }

  .cart-count {
    top: 0;
    right: 50px;
    padding: 5px;
    font-size: 0.7rem;
  } */
}

@media (max-width: 480px) {
  .ourProduct-grid {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 5px;
  }

  .ourProduct-card {
    padding: 10px;
  }

  .ourProduct-card img {
    height: 200px;
  }

  .ourProduct-card h3 {
    font-size: 0.9rem;
  }

  .ourProductsHeader h2 {
    font-size: 1.3rem;
  }
}
