.carousel-container {
  width: 100%;
  max-width: 100%;
  height: 630px;
  margin: auto;
  margin-top: 38px;
  overflow: hidden;
}

.carousel-container img {
  width: 100%;
  height: 550px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

@media (max-width: 768px) {
  .carousel-container {
    height: 400px;
  }

  .carousel-container img {
    height: 350px;
    object-fit: cover;
  }
}

/* Mobile Styles (max-width: 480px) */
@media (max-width: 480px) {
  .carousel-container {
    height: 250px;
  }

  .carousel-container img {
    height: 220px;
    object-fit: cover;
  }
}
