.forgot-password-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 350px;
  max-width: 100%;
  text-align: center;
  margin-top: 10%;
  margin-left: 35%;
}

.subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #333;
}

.instructions {
  font-size: 14px;
  color: #707070;
  margin-bottom: 20px;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
}

.forgot-password-form label {
  text-align: left;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.forgot-password-form input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
}

.reset-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.reset-button:hover {
  background-color: #f13434;
}

.back-link {
  display: inline-block;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.back-link:hover {
  text-decoration: none;
}

/*  large tablets and desktops */
@media (max-width: 1024px) {
  .forgot-password-card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    width: 70%;
  }
}

/* small tablets and mobile devices */
@media (max-width: 768px) {
  .forgot-password-card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    width: 85%;
  }
}

/* for mobile devices */
@media (max-width: 480px) {
  .forgot-password-card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    width: 85%;
    padding: 20px;
  }

  .subtitle {
    font-size: 18px;
  }

  .instructions {
    font-size: 12px;
  }

  .forgot-password-form input {
    font-size: 12px;
    padding: 8px;
  }

  .reset-button {
    font-size: 14px;
    padding: 8px;
  }

  .back-link {
    font-size: 12px;
  }
}
