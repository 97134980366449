.product-card {
  border: 1px solid #ddd;
  height: 90%;
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.product-card:hover {
  transform: scale(1.05);
}
.product-card img {
  width: 200px;
  height: 200px;
}
.product-card .product-info {
  margin-top: 5px;
  flex-grow: 1;
}
.product-card .product-info p {
  margin: 2px 0;
}

.product-card .new-price {
  color: #ff4d4d;
  margin-top: 2px;
  font-size: 1.3em;
}
.enquiry-btn {
  background-color: #ff4d4d;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;
}

.notification {
  position: fixed;
  top: 10px;
  left: 85%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 15px 20px;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1em;
  text-align: center;
  width: auto;
}

/* Tablet Styles (max-width: 768px) */
@media (max-width: 768px) {
  .product-card {
    padding: 8px;
    margin: 8px;
  }

  .product-card img {
    width: 100%;
    height: 150px;
  }

  .enquiry-btn {
    padding: 8px;
  }

  .product-card .product-info p {
    font-size: 0.9em;
  }

  .product-card .new-price {
    font-size: 1em;
  }
}

/* Mobile Styles (max-width: 480px) */
@media (max-width: 480px) {
  .product-card {
    padding: 5px;
    margin: 5px;
  }

  .product-card img {
    width: 100%;
    height: 120px;
  }

  .enquiry-btn {
    padding: 6px;
  }

  .product-card .product-info p {
    font-size: 0.8em;
  }

  .product-card .new-price {
    font-size: 0.9em;
  }

  .notification {
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    padding: 10px 15px;
    font-size: 0.9em;
  }
}
