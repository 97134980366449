.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.modal-large {
  max-width: 900px;
}

.modal-body {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.modal-left {
  flex: 1;
  padding-right: 20px;
}

.modal-right {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 500px;
}

.modal-image {
  width: 100%;
  height: 320px;
  object-fit: contain;
  border-radius: 8px;
}

.modal-info h2 {
  margin: 10px 0;
}

.new-price {
  font-size: 1.5em;
  color: #ff4d4d;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.quantity-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  margin: 0 10px;
}

.quantity-input {
  width: 50px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
}

.giftbox-items-table {
  width: 100%;
  background-color: White;
  border-collapse: collapse;
}

.giftbox-items-table th,
.giftbox-items-table td {
  padding: 10px;
  text-align: left;
  background-color: White;
  border-bottom: 1px solid #ddd;
}

.giftbox-items-table th {
  background-color: White;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    max-width: 400px;
    padding: 20px;
    height: auto;
    max-height: 90vh;
    overflow-y: auto;
  }

  .modal-body {
    flex-direction: column;
    gap: 15px;
  }

  .modal-left,
  .modal-right {
    padding: 0;
    margin-bottom: 15px;
  }

  .modal-image {
    height: 180px;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  .modal-info h2 {
    font-size: 1.3em;
  }

  .new-price {
    font-size: 1.2em;
  }

  .quantity-control {
    margin-top: 10px;
  }

  .quantity-btn {
    padding: 5px;
    font-size: 14px;
  }

  .quantity-input {
    width: 40px;
    font-size: 14px;
  }

  .giftbox-items-table {
    width: 100%;
    font-size: 0.9em;
    background-color: transparent;
  }

  .giftbox-items-table th,
  .giftbox-items-table td {
    padding: 8px;
  }

  .close-button {
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
}

/* Mobile */
@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    max-width: 350px;
    padding: 15px;
    max-height: 85vh;
    margin-top: 10%;
    overflow-y: auto;
  }

  .modal-image {
    height: 120px;
  }

  .new-price {
    font-size: 1.1em;
  }

  .quantity-control {
    margin-top: 8px;
  }

  .quantity-btn {
    padding: 3px;
    font-size: 12px;
  }

  .quantity-input {
    width: 35px;
    font-size: 12px;
  }

  .giftbox-items-table {
    font-size: 0.8em;
  }

  .close-button {
    top: 5px;
    right: 5px;
  }
}
