.terms-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.terms-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: left;
}
.terms-modal-content p {
  color: black;
}
.close-modal-btn-top {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #ff4d4d;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.close-modal-btn-top:hover {
  color: #f81e1e;
}

.close-modal-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 40%;
  cursor: pointer;
}

.close-modal-btn:hover {
  background: #f43131;
}
